import React, { Component, createRef } from 'react';
import { func, object, shape, string, number } from 'prop-types';
import { Scoped, k } from 'kremling';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { List, ListItemButton, ListItemText, Stack, Box, Typography } from '@mui/material';
import { Button, Dropdown } from 'components';
const local = DateTime.local();
import { TypeAhead } from 'components/type-ahead/type-ahead.component';
import { spacing } from '@mui/system';
const waitUntilDefaults = {
  start_time: '08:00',
  end_time: '16:00',
  timezone: local.zoneName,
  days: [0, 1, 2, 3, 4]
};
const hourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minuteOptions = ['00', '15', '30', '45'];
export class ActionRateLimit extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      unit: string,
      amount: number,
      customers: number,
      wait_until: object
    })
  };
  state = {
    filteredTimezones: [{
      value: 'America/New_York',
      label: 'Eastern Standard Time (EST)'
    }, {
      value: 'America/Chicago',
      label: 'Central Standard Time (CST)'
    }, {
      value: 'America/Denver',
      label: 'Mountain Standard Time (MST)'
    }, {
      value: 'America/Los_Angeles',
      label: 'Pacific Standard Time (PST)'
    }, {
      value: 'America/Anchorage',
      label: 'Alaska Standard Time (AKST)'
    }, {
      value: 'Pacific/Honolulu',
      label: 'Hawaii Standard Time (HST)'
    }, {
      value: 'Etc/GMT+4',
      label: 'Atlantic Standard Time (AST)'
    }, {
      value: 'Etc/GMT+7',
      label: 'Arizona'
    }],
    listRef: createRef(),
    timezoneSelected: null
  };
  toggleWaitUntil = () => {
    this.props.update({
      wait_until: this.props.data.wait_until.start_time ? {} : waitUntilDefaults
    });
  };
  onUnitChange = e => {
    const unit = e.target.value;
    let amount = this.props.data.amount || 1;
    if (unit === 'minutes' && amount < 15) {
      amount = 15;
    }
    if (unit === 'years') {
      amount = 1;
    }
    this.props.update({
      amount,
      unit
    });
  };
  onAmountBlur = () => {
    let unit = this.props.data.unit;
    let amount = this.props.data.amount || 1;
    if (unit === 'years' && amount !== 1) {
      this.props.update({
        amount: 1
      });
    } else if (unit === 'minutes' && amount < 15) {
      this.props.update({
        amount: 15
      });
    }
  };
  onAmountChange = e => {
    if (this.props.data.unit !== 'years') {
      const amount = parseFloat(e.target.value);
      this.props.update({
        amount
      });
    }
  };
  onCustomersChange = e => {
    const customers = parseInt(e.target.value, 10);
    this.props.update({
      customers: isNaN(customers) || customers < 1 ? 1 : customers
    });
  };

  // updates timezone with the timezoneSelected
  componentDidMount() {
    if (this.props.data.wait_until.timezone) {
      this.setState({
        timezoneSelected: this.state.filteredTimezones.find(timezone => timezone.value === this.props.data.wait_until.timezone)?.label
      });
    }
  }
  render() {
    const {
      unit,
      amount,
      customers,
      wait_until
    } = this.props.data;
    const showOptions = !!(wait_until && wait_until.start_time);
    return <Scoped css={css}>
        <div>
          <div className="form-group">
            <label>Number of Customers</label>
            <div className="row">
              <div className="col-12">
                <input type="number" className="form-control" value={customers} onChange={this.onCustomersChange} />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Duration</label>
            <div className="row">
              <div className="col-3">
                <input type="number" className="form-control" value={amount} onChange={this.onAmountChange} onBlur={this.onAmountBlur} />
              </div>
              <div className="col-9">
                <select className="form-control" value={unit} onChange={this.onUnitChange}>
                  <option value="minutes">Minute(s)</option>
                  <option value="hours">Hour(s)</option>
                  <option value="days">Day(s)</option>
                  <option value="weeks">Week(s)</option>
                  <option value="months">Month(s)</option>
                  <option value="quarters">Quarter(s)</option>
                  <option value="years">Year</option>
                </select>
              </div>
            </div>
            <small>Smallest duration is 15 minutes.</small>
          </div>
          <div className="form-group mb-sm">
            <label>
              <input type="checkbox" onChange={() => {
              if (!this.state.timezoneSelected) {
                this.setState({
                  timezoneSelected: this.state.filteredTimezones.find(timezone => timezone.value === this.props.data.timezone)?.label
                });
              }
              this.toggleWaitUntil();
            }} checked={showOptions} />
              Enable time and day options
            </label>
          </div>
          {this.renderOptions(showOptions)}
        </div>
      </Scoped>;
  }
  deconstructTime = time => {
    const [hourDisplay, minute] = time.split(':');
    const hourNumber = parseInt(hourDisplay);
    const meridiem = hourNumber >= 12 ? 'PM' : 'AM';
    const hour = hourNumber === 0 ? 12 : hourNumber > 12 ? hourNumber - 12 : hourNumber;
    return {
      hour,
      minute,
      meridiem
    };
  };
  updateTime = (key, time) => {
    let newTime = '';
    if (time.hour === 12 && time.meridiem === 'PM') {
      newTime += '12';
    } else if (time.hour === 12 && time.meridiem === 'AM') {
      newTime += '00';
    } else if (time.meridiem === 'PM') {
      newTime += time.hour + 12;
    } else {
      newTime += time.hour < 10 ? `0${time.hour}` : time.hour;
    }
    newTime += `:${time.minute}`;
    this.props.update({
      wait_until: {
        ...this.props.data.wait_until,
        [key]: newTime
      }
    });
  };
  isDaysChecked = day => {
    return this.props.data.wait_until.days.includes(day);
  };
  updateDays = day => {
    const days = this.props.data.wait_until.days;
    this.props.update({
      wait_until: {
        ...this.props.data.wait_until,
        days: days.includes(day) ? [...days.splice(0, days.indexOf(day)), ...days.splice(days.indexOf(day) + 1)] : [...days, day]
      }
    });
  };
  selectTimezone = (timezone, label) => {
    this.props.update({
      wait_until: {
        ...this.props.data.wait_until,
        timezone
      }
    });
    this.setState({
      timezoneSelected: label
    });
    this.state.listRef.current.close();
  };
  renderOptions = showOptions => {
    if (!showOptions) return null;
    const {
      wait_until
    } = this.props.data;
    const startTime = this.deconstructTime(wait_until.start_time);
    const endTime = this.deconstructTime(wait_until.end_time);
    return <>
        <Typography sx={{
        textTransform: 'uppercase',
        color: '#808080',
        fontSize: '12px',
        pb: '2px'
      }}>
          {' '}
          Between
        </Typography>

        <Stack sx={{
        pb: '2px'
      }} direction="row" spacing={2}>
          <Box>
            <select className="form-control" disabled={!showOptions} value={startTime.hour} onChange={e => {
            this.updateTime('start_time', {
              ...startTime,
              hour: parseInt(e.target.value)
            });
          }}>
              {hourOptions.map(hour => <option key={hour.toString()} value={hour}>
                  {hour}
                </option>)}
            </select>
          </Box>
          <Box>
            <select className="form-control" disabled={!showOptions} value={startTime.minute} onChange={e => {
            this.updateTime('start_time', {
              ...startTime,
              minute: e.target.value
            });
          }}>
              {minuteOptions.map(minute => <option key={minute.toString()} value={minute}>
                  {minute}
                </option>)}
            </select>
          </Box>
          <Box>
            <select className="form-control" disabled={!showOptions} value={startTime.meridiem} onChange={e => {
            this.updateTime('start_time', {
              ...startTime,
              meridiem: e.target.value
            });
          }}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </Box>
        </Stack>
        <Typography sx={{
        textTransform: 'uppercase',
        color: '#808080',
        fontSize: '12px',
        pb: '2px'
      }}>
          {' '}
          and
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box>
            <select className="form-control" disabled={!showOptions} value={endTime.hour} onChange={e => {
            this.updateTime('end_time', {
              ...endTime,
              hour: parseInt(e.target.value)
            });
          }}>
              {hourOptions.map(hour => <option key={hour.toString()} value={hour}>
                  {hour}
                </option>)}
            </select>
          </Box>
          <Box>
            <select className="form-control" disabled={!showOptions} value={endTime.minute} onChange={e => {
            this.updateTime('end_time', {
              ...endTime,
              minute: e.target.value
            });
          }}>
              {minuteOptions.map(minute => <option key={minute.toString()} value={minute}>
                  {minute}
                </option>)}
            </select>
          </Box>
          <Box>
            <select className="form-control" disabled={!showOptions} value={endTime.meridiem} onChange={e => {
            this.updateTime('end_time', {
              ...endTime,
              meridiem: e.target.value
            });
          }}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </Box>
        </Stack>
        <div className="form-group mb-none">
          <label>On the following days</label>
        </div>
        <div className="form-group time-delay__days">
          <label>
            <input type="checkbox" checked={this.isDaysChecked(0)} onChange={() => this.updateDays(0)} /> Monday
          </label>
          <label>
            <input type="checkbox" checked={this.isDaysChecked(1)} onChange={() => this.updateDays(1)} /> Tuesday
          </label>
          <label>
            <input type="checkbox" checked={this.isDaysChecked(2)} onChange={() => this.updateDays(2)} /> Wednesday
          </label>
          <label>
            <input type="checkbox" checked={this.isDaysChecked(3)} onChange={() => this.updateDays(3)} /> Thursday
          </label>
          <label>
            <input type="checkbox" checked={this.isDaysChecked(4)} onChange={() => this.updateDays(4)} /> Friday
          </label>
          <label>
            <input type="checkbox" checked={this.isDaysChecked(5)} onChange={() => this.updateDays(5)} /> Saturday
          </label>
          <label>
            <input type="checkbox" checked={this.isDaysChecked(6)} onChange={() => this.updateDays(6)} /> Sunday
          </label>
        </div>
        <div className="row">
          <div className="col-11">
            <div className="form-group">
              <label>
                Select Timezone <span className="form-error">*</span>
              </label>
              <Dropdown size="md" ref={this.state.listRef} contentHeight={280} trigger={() => <Button block dropdown>
                    {this.state.timezoneSelected || 'Select Timezone'}
                  </Button>} content={() => <List sx={{
              height: '100%',
              width: '100%'
            }}>
                    {this.state.filteredTimezones.map(timezone => <ListItemButton sx={{
                height: '100%',
                width: '100%'
              }} key={timezone.value} onClick={() => this.selectTimezone(timezone.value, timezone.label)}>
                        <ListItemText primary={timezone.label} />
                      </ListItemButton>)}
                  </List>}></Dropdown>
            </div>
          </div>
        </div>
      </>;
  };
}
const css = {
  styles: `[kremling="i29"] body,body[kremling="i29"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i29"] .time-delay__overlay-block,[kremling="i29"].time-delay__overlay-block {
  position: relative;
  cursor: default;
  user-select: none;
}

[kremling="i29"] .time-delay__overlay-block::after,[kremling="i29"].time-delay__overlay-block::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.5);
}

[kremling="i29"] .time-delay__time,[kremling="i29"].time-delay__time {
  width: 64%;
}

[kremling="i29"] .time-delay__days label,[kremling="i29"].time-delay__days label {
  display: inline-flex;
  width: 11.5rem;
  align-items: center;
}

[kremling="i29"] .time-delay__divider,[kremling="i29"].time-delay__divider {
  padding-top: 0.4rem;
  margin-bottom: 1.6rem;
  border-bottom: solid 1px #d3d3d3;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}`,
  id: 'i29',
  namespace: 'kremling'
};