import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { Scoped, k } from 'kremling';
import { isFunction, get } from 'lodash';
import { Button } from 'components/button/button.component';
import { Modal } from 'components/modal/modal.component';
import { FormWizardSteps } from './form-wizard-steps.component';
import { FormWizardDetails } from './form-wizard-details.component';
import { api } from 'shared/api';
import { FormWizardChooser } from './form-wizard-chooser.component';
import { FormWizardMapper } from './form-wizard-mapper.component';
import { UserStateContext } from 'context/user-state-context';
import { Loader } from 'components/loader/loader.component';
import { FormWizardScript } from './form-wizard-script.component';
import { postForm } from 'shared/forms.api';
import { validateUrl } from './form-wizard.util';
const defaultState = {
  step: 1,
  searchingForForms: false,
  saving: false,
  shouldSearch: true,
  forms: [],
  showFormRedirect: false,
  // data for form
  formName: '',
  formUrl: '',
  formRedirect: '',
  formFields: [],
  formHtmlIndex: 0,
  formHtmlTag: null
};
export class FormWizard extends Component {
  static contextType = UserStateContext;
  static propTypes = {
    open: bool,
    onClose: func
  };
  state = Object.assign({}, defaultState);
  componentDidUpdate(prevProps, prevState) {
    const {
      step,
      shouldSearch
    } = this.state;
    if (prevState.step === 1 && step === 2 && shouldSearch) {
      this.searchForForms();
    }
  }
  changeStep = step => {
    this.setState({
      step
    });
  };
  forceSearchTime = time => {
    let _resolve;
    setTimeout(() => _resolve(), time);
    return new Promise(resolve => _resolve = resolve);
  };
  getForms = () => {
    const {
      formUrl
    } = this.state;
    return api.post(`/webform/scrape`, {
      url: formUrl
    });
  };
  searchForForms = () => {
    this.setState({
      searchingForForms: true
    });
    Promise.all([this.getForms(), this.forceSearchTime(1000)]).then(([{
      data
    }]) => {
      if (data && data.length && data[0] && data[0].fields) {
        this.setState({
          shouldSearch: false,
          forms: data,
          formHtmlIndex: 0,
          searchingForForms: false,
          formFields: data[0].fields.map(field => ({
            ...field,
            field: null
          }))
        });
      } else {
        this.setState({
          forms: [],
          formFields: [],
          formHtmlIndex: 0,
          searchingForForms: false
        });
      }
    }).catch(err => {
      this.setState({
        forms: [],
        formFields: [],
        formHtmlIndex: 0,
        searchingForForms: false
      });
    });
  };
  updateState = state => {
    this.setState(prevState => {
      return isFunction(state) ? state(prevState) : state;
    });
  };
  validateStep1 = () => {
    const {
      formName,
      formUrl,
      formRedirect
    } = this.state;
    return !!(formName && formUrl && validateUrl(formUrl) && (!formRedirect || validateUrl(formRedirect)));
  };
  validateStep2 = () => {
    const {
      forms,
      formFields
    } = this.state;
    return !!(forms.length && formFields.length);
  };
  validateStep3 = () => {
    return true;
  };
  validateSteps = step => {
    if (step === 1) {
      return this.validateStep1();
    }
    if (step === 2) {
      return this.validateStep2();
    }
    return this.validateStep3();
  };
  save = () => {
    const {
      formName,
      formUrl,
      formRedirect,
      formFields,
      formHtmlIndex,
      formHtmlTag
    } = this.state;
    this.setState({
      saving: true
    });
    postForm({
      company: this.context.asCompany.id,
      name: formName,
      url: formUrl,
      redirect: formRedirect,
      fields: formFields,
      html_index: formHtmlIndex,
      html_tag: formHtmlTag
    }).then(({
      data
    }) => {
      this.setState({
        step: 4,
        formId: data.id,
        saving: false
      });
    });
  };
  onClose = e => {
    this.props.onClose(e);
    setTimeout(() => {
      this.setState(Object.assign({}, defaultState));
    }, 500);
  };
  render() {
    const {
      open
    } = this.props;
    const {
      step,
      formRedirect,
      searchingForForms,
      forms,
      formUrl,
      formHtmlIndex,
      formFields,
      saving,
      showFormRedirect,
      formId,
      formName
    } = this.state;
    return <Modal open={open} size="lg" type="scroll" title="Track New Form" onClose={this.onClose}>
        <Scoped css={css}>
          <div className="modal__body">
            {!saving && <FormWizardSteps step={step} changeStep={this.changeStep} step1Validated={this.validateStep1()} step2Validated={this.validateStep2()} step3Validated={this.validateStep3()} />}
            {step === 1 && !saving && <FormWizardDetails formRedirect={formRedirect} showFormRedirect={showFormRedirect} updateState={this.updateState} formUrl={formUrl} formName={formName} />}
            {step === 2 && !saving && <FormWizardChooser searchingForForms={searchingForForms} forms={forms} updateState={this.updateState} formHtmlIndex={formHtmlIndex} />}
            {step === 3 && !saving && <FormWizardMapper formFields={formFields} updateState={this.updateState} />}

            {step === 4 && !saving && <FormWizardScript formId={formId} />}

            {saving && <div className="wizard-saving">
                Saving
                <Loader />
              </div>}
          </div>
          {!(searchingForForms || saving) && <div className="new-form-actions">
              <Button actionType="flat" onClick={this.onClose}>Cancel</Button>
              <div className="new-form-actions__right">
                {step > 1 && step < 4 && <Button onClick={() => this.changeStep(step - 1)}>
                    Back
                  </Button>}
                {step < 3 && <Button actionType="primary" onClick={() => this.changeStep(step + 1)} disabled={!this.validateSteps(step)}>
                    Next
                  </Button>}
                {step === 3 && <Button actionType="primary" onClick={this.save}>
                    Next
                  </Button>}
                {step === 4 && <Button actionType="primary" onClick={() => {
              this.props.onSubmit();
              this.onClose();
            }}>
                    Finish
                  </Button>}
              </div>
            </div>}
        </Scoped>
      </Modal>;
  }
}
const css = {
  styles: `[kremling="i26"] body,body[kremling="i26"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i26"] .new-form-actions,[kremling="i26"].new-form-actions {
  border-top: solid 0.1rem var(--color-grey-100);
  padding: 1.6rem;
  text-align: right;
}

[kremling="i26"] .new-form-actions,[kremling="i26"].new-form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

[kremling="i26"] .new-form-actions__right button,[kremling="i26"].new-form-actions__right button {
  margin-left: 1.6rem;
}

[kremling="i26"] .new-form-actions__right,[kremling="i26"].new-form-actions__right {
  display: flex;
  align-items: center;
  justify-content: center;
}

[kremling="i26"] .wizard-saving,[kremling="i26"].wizard-saving {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30rem;
  font-weight: 700;
  font-size: 1.6rem;
}`,
  id: 'i26',
  namespace: 'kremling'
};