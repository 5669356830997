import React, { Component } from 'react';
import { bool } from 'prop-types';
import { Scoped, a, k } from 'kremling';
import { Icon } from '../icon/icon.component';
import { Loader } from '../loader/loader.component';
export class Save extends Component {
  static propTypes = {
    saving: bool
  };
  render() {
    const {
      saving
    } = this.props;
    return <Scoped css={css}>
        <div className={a('save-container').m('save-container--saving', saving)}>
          <div className="save-loading">
            <Loader size="sm" /> Saving
          </div>
          <div className="save-done">
            <Icon name="fa-solid-check-circle" size={15} /> <span>Saved</span>
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i21"] body,body[kremling="i21"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i21"] .save-container,[kremling="i21"].save-container {
  color: #a3a3a3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.4rem;
  user-select: none;
  position: relative;
}

[kremling="i21"] .save-loading,[kremling="i21"].save-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1.4rem;
  transform: translateY(-1rem);
  opacity: 0;
  transition: opacity 300ms ease, transform 300ms ease;
}

[kremling="i21"] .save-loading>*,[kremling="i21"].save-loading>* {
  margin-right: 0.4rem;
}

[kremling="i21"] .save-done,[kremling="i21"].save-done {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1.4rem;
  transform: translateY(0);
  opacity: 1;
  transition: opacity 300ms ease, transform 300ms ease;
}

[kremling="i21"] .save-container.save-container--saving .save-loading,[kremling="i21"].save-container.save-container--saving .save-loading {
  opacity: 1;
  transform: translateY(0);
}

[kremling="i21"] .save-container.save-container--saving .save-done,[kremling="i21"].save-container.save-container--saving .save-done {
  opacity: 0;
  transform: translateY(1rem);
}

[kremling="i21"] .save-container svg,[kremling="i21"].save-container svg {
  margin-right: 0.6rem;
  margin-top: -0.1rem;
}`,
  id: 'i21',
  namespace: 'kremling'
};